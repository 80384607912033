// #/services/s3/actions/getSecrets.ts

import superjson from 'superjson';
import { join as pathJoin } from 'path';
import { GetObjectOutput } from 'aws-sdk/clients/s3';

import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';

import checkFileExists from './checkFileExists';
import checkFolderExists from './checkFolderExists';
import upsertJsonFile from './json/upsertJsonFile';
import { PATH_USERS } from '../filepaths';

export default async function getSecrets(address: string): Promise<any> {
  try {
    const userHome = pathJoin(PATH_USERS, address);
    const secretsKey = pathJoin(userHome, 'secrets.json');

    // Check if the account folder exists
    const folderExists = await checkFolderExists(userHome);
    if (!folderExists) {
      console.warn(`Account folder not found: ${userHome}`);
      return null;
    }

    // Check if the secrets file exists
    const fileExists = await checkFileExists({
      s3ObjectKey: secretsKey,
    });
    if (!fileExists) {
      console.warn(`Secrets file not found: ${secretsKey}`);
      await upsertJsonFile({
        s3ObjectKey: secretsKey,
        updatedJson: {},
      });
      return {};
    }

    // If the folder and file exist, get the secrets
    const data: GetObjectOutput = await s3
      .getObject({ Bucket: AWS_S3_BUCKET, Key: secretsKey })
      .promise();

    if (!data.Body) {
      console.warn('Secrets file is empty');
      await upsertJsonFile({
        s3ObjectKey: secretsKey,
        updatedJson: {},
      });
      return {};
    }

    const secrets = superjson.parse(data.Body.toString());

    return secrets;
  } catch (err: any) {
    console.error(`Error retrieving secrets file: ${err}`);
    return null;
  }
}

/*
SSS
W
ORM
*/
