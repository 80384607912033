// #/services/s3/actions/updateProfile.ts

import { join as pathJoin } from 'path';

import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';
import { IProfile } from '#/types/profile';
import { parseJson, stringifyJson } from '#/utils/json';

import { PATH_USERS } from '../filepaths';

export default async function updateProfile(args: {
  address: string;
  profile: IProfile;
}): Promise<boolean> {
  const { address, profile } = args;

  const userHome = pathJoin(PATH_USERS, address);
  const profileKey = pathJoin(userHome, 'profile.json');

  try {
    const currentProfile = await s3
      .getObject({
        Bucket: AWS_S3_BUCKET,
        Key: profileKey,
      })
      .promise();

    const newProfile = {
      ...parseJson(
        typeof currentProfile?.Body !== 'string'
          ? currentProfile?.Body?.toString() ?? '{}'
          : currentProfile?.Body ?? '{}',
      ),
      ...profile,
      address,
      updated_at: new Date().toISOString(),
    };

    const putProfileParams = {
      Bucket: AWS_S3_BUCKET,
      Key: profileKey,
      Body: stringifyJson(newProfile),
    };

    await s3.putObject(putProfileParams).promise();

    return true;
  } catch (err) {
    console.error(`Error updating profile: ${err}`);
    return false;
  }
}
