// #/utils/json/parseJson.ts
import superjson from 'superjson';

import { logger } from '#/logger';

// const bigintReplacer = (key, value) =>
//   typeof value === 'bigint' ? value.toString() : value; // return everything else unchanged

export function parseJson<T>(value: string) {
  try {
    const parsed = superjson.parse<T>(value);
    if (typeof parsed === 'string') {
      return JSON.parse(parsed);
    }
    if (parsed == null) {
      throw new Error('Parsed value is null');
    }
    return parsed;
  } catch (error: any) {
    logger.error(`[parseJson:superjson] Warn: ${error.toString()}`);
    logger.error(`typeof value: ${typeof value}, value: \`${value}\``);
    try {
      return JSON.parse(value);
    } catch (error: any) {
      logger.error(`[parseJson:JSON.parse] Error: ${error.toString()}`);
      logger.error(`typeof value: ${typeof value}, value: \`${value}\``);
      return value;
    }
  }
}
