// #/utils/json/stringifyJson.ts
import superjson from 'superjson';

import { logger } from '#/logger';
import { isObject } from 'lodash';
import { parseJson } from './parse';

export const bigintReplacer = (key: string, value: any) =>
  typeof value === 'bigint' ? value.toString() : value; // return everything else unchanged

export function stringifyJson(value: any): string {
  try {
    // If the value is already a string, assume it's already serialized
    if (typeof value === 'string') {
      return value;
    }

    // // If the value is an object, parse it first
    // if (isObject(value)) {
    //   const parsedValue = parseJson(value);
    //   console.debug('[stringifyJson] parsedValue', parsedValue);
    //   return superjson.stringify(parsedValue);
    // }

    // Otherwise, use superjson to stringify the value
    return superjson.stringify(value);
  } catch (error: any) {
    logger.error(
      `[stringifyJson > superjson.stringify] error: ${error}, value: ${value}`,
    );
    try {
      return JSON.stringify(value, bigintReplacer);
    } catch (error: any) {
      logger.error(
        `[stringifyJson > JSON.stringify] error: ${error}, value: ${value}`,
      );
      return `${value}`;
    }
  }
}
