import S3 from 'aws-sdk/clients/s3';

import {
  AWS_S3_ACCESS_KEY,
  AWS_S3_ENDPOINT,
  AWS_S3_SECRET_KEY,
  AWS_S3_REGION,
} from '#/config/constants';

export * from './actions';

export const s3 = new S3({
  endpoint: AWS_S3_ENDPOINT,
  accessKeyId: AWS_S3_ACCESS_KEY,
  secretAccessKey: AWS_S3_SECRET_KEY,
  region: AWS_S3_REGION,
  s3ForcePathStyle: true,
});
