// #/logger/logger.ts

import pino from 'pino';
import { logflarePinoVercel } from 'pino-logflare';

// create pino-logflare console stream for serverless functions and send function for browser logs
// Browser logs are going to: https://logflare.app/sources/13989
// Vercel log drain was setup to send logs here: https://logflare.app/sources/28257

const { stream, send } = logflarePinoVercel({
  apiKey: '0ea3f85ada164944eb4cd4e48c8d463978cbf034d232be76ba2bca82de046cb1',
  sourceToken: '81630358-2600-45a1-84bf-b65ad1ed84e2',
});

// const transport = pino.transport({
//   target: '@baselime/pino-transport',
//   options: { baselimeApiKey: process.env.BASELIME_API_KEY },
// });

// create pino logger
export const logger = pino(
  {
    browser: {
      transmit: {
        level: 'info',
        send: send,
      },
    },
    level: 'debug',
    base: {
      env: process.env.NODE_ENV,
      revision: process.env.VERCEL_GITHUB_COMMIT_SHA,
      pid: process?.pid, // Include the process ID
    },
  },
  stream,
);

export default logger;
