// ./lib/actions/simplifi/s3/getDefaultProfile.ts
import { Profile, Role } from '#/types/profile';

export default function getDefaultProfile(address: string): Profile {
  return {
    publicAddress: address,
    details: {
      publicAddress: address,
      bio: '',
      avatarUrl: '',
      websiteUrl: '',
      firstName: '',
      lastName: '',
      roles: new Set<Role>(),
    },
    isAffiliate: false,
    // wallets: {
    //   0: address,
    // },
    // organizations: [],
    // finance: {
    //   subscription: {
    //     tier: 'free',
    //   },
    //   balance: '',
    //   tokens: {},
    // },
    // account: {
    //   phoneNumber: '',
    //   created_at: new Date().toISOString(),
    //   updated_at: new Date().toISOString(),
    //   deleted_at: null,
    //   last_login: new Date().toISOString(),
    // },
    // socials: {
    //   email: '',
    //   twitter: '',
    //   github: '',
    //   twitterUsername: '',
    //   githubUsername: '',
    //   linkedinUrl: '',
    //   facebookUrl: '',
    //   instagramUrl: '',
    //   tiktokUrl: '',
    //   snapchatUsername: '',
    //   telegramUsername: '',
    // },
  };
}
